import {EventBus} from '@/js/app/_bridge/EventBus'

angular.module('eOpti.directives.table').directive('baseTable', ['$sce', function ($sce) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/app/views/directives/table/base-table.html',
        scope: {
            endPoint: '@',
            header: '@',
            subHeader: '@',
            subHeaderNoTranslate: '@',
            ref: '@',
            renderers: '=',
            additionalRow: '=',
            customData: '&',
            beforeFetch: '&',
            events: '&'
        },
        controller: [
            '$scope',
            '$state',
            '$stateParams',
            '$localStorage',
            '$http',
            '$filter',
            '$attrs',
            'uac',
            function ($scope, $state, $stateParams, $localStorage, $http, $filter, $attrs, uac) {
                $scope.operators = [
                    {
                        type: '=',
                        name: 'default.basetable.operator.EQUALS'
                    },
                    {
                        type: '!=',
                        name: 'default.basetable.operator.NOTEQUALS'
                    },
                    {
                        type: '>',
                        name: 'default.basetable.operator.GREATERTHAN'
                    },
                    {
                        type: '<',
                        name: 'default.basetable.operator.LESSTHAN'
                    },
                    {
                        type: '>=',
                        name: 'default.basetable.operator.GREATERTHANEQUAL'
                    },
                    {
                        type: '<=',
                        name: 'default.basetable.operator.LESSTHANEQUAL'
                    },
                    {
                        type: '*~',
                        name: 'default.basetable.operator.LIKE'
                    },
                    {
                        type: '!~',
                        name: 'default.basetable.operator.NOTLIKE'
                    },
                    {
                        type: '~~',
                        name: 'default.basetable.operator.REGEXP'
                    },
                    {
                        type: '!~~',
                        name: 'default.basetable.operator.NOTREGEXP'
                    }
                ];

                $scope.criteria = {
                    page: $stateParams.page,
                    pageSize: $localStorage[$state.current.name + '-pageSize'] ||
                        ($stateParams.pageSizeWarehouse && uac.user.activeDepartmentId === 49 ? $stateParams.pageSizeWarehouse : $stateParams.pageSize),
                    sortBy: $localStorage[$state.current.name + '-sortBy'] || $stateParams.sortBy,
                    sortOrder: $localStorage[$state.current.name + '-sortOrder'] || $stateParams.sortOrder,
                    search: $stateParams.resetSearch ? [] : ($stateParams.search || $localStorage[$state.current.name + '-search'] || []),
                    columns: $stateParams.columns || $localStorage[$state.current.name + '-columns-' +
                        JSON.stringify($stateParams.search)]
                };

                $scope.data = {
                    headers: {
                        count: 0,
                        search: []
                    },
                    columns: [],
                    rows: [],
                    sum: []
                }

                $scope.fetching = false;
                $scope.columnEditor = false;
                $scope.filterEditor = false;
                $scope.filters = [];
                $scope.enabledColumns = []

                let select_editable_loading = {},
                    setSelectEditableLoading = row_id => {
                        select_editable_loading[row_id] = false

                        if(!Object.values(select_editable_loading).filter(bool => bool).length) {
                            $scope.$emit('select_editable_was_loaded')
                        }
                    }

                let getSelectEditable = data => {
                    let found = [],
                        rxp = /{([^}]+)}/g,
                        curMatch

                    while(curMatch = rxp.exec(data[0])) {
                        found.push(curMatch[1]);
                    }

                    angular.forEach($scope.data.rows, row => {
                        select_editable_loading[row.id] = true
                    })

                    angular.forEach($scope.data.rows, row => {
                        let path = data[0]

                        angular.forEach(found, bracket => {
                            path = path.replace('{' + bracket + '}', row[bracket] === null ? 0 : row[bracket])
                        })

                        $http.get(path).then(res => {
                            row[data[1] + '_select'] = res.data

                            setSelectEditableLoading(row.id)
                        }, () => {
                            $scope.$emit('error', 'default.notify.error.SELECT')

                            setSelectEditableLoading(row.id)
                        })
                    })


                }

                $scope.columnOptionsToSortedArray = function (column, sort) {
                    if (!column.parsedOptions) {
                        var arr = [];
                        angular.forEach(column.options, function (option, key) {
                            arr.push({
                                value: option,
                                translated: $filter('translate')(option),
                                key: key
                            })
                        })

                        column.parsedOptions = sort ? arr.sort(function (a,b) {
                            var x = a.translated.toLowerCase(),
                                y = b.translated.toLowerCase();

                            return x < y ? -1 : x > y ? 1 : 0;
                        }) : arr;
                    }

                    return column.parsedOptions
                };

                $scope.fetchData = function () {
                    $scope.fetching = true;

                    $scope.checkboxAll = [];

                    let data = $scope.getCriteria()
                    $localStorage[$state.current.name + '-search'] = data.search;

                    return $http.post($scope.endPoint, data).then(function (response) {
                            $scope.data.headers = response.data.headers
                            $scope.data.columns = response.data.columns
                            $scope.data.rows = response.data.rows
                            $scope.data.actions = response.data.actions
                            $scope.data.sum = response.data.sum

                            $scope.enabledColumns.length = 0
                            $scope.savedFilters = response.data.filters
                            $scope.multilevel = response.data.multilevel

                            var selectEditableExists = [];

                            angular.forEach($scope.data.columns, function (column) {
                                column.defaults = {
                                    visible: column.visible
                                };
                                angular.forEach($scope.criteria.columns, function (crit) {
                                    if (column.id == crit.id) {
                                        column.visible = crit.visible;
                                    }
                                });

                                if (!column.disableFilter) {
                                    $scope.enabledColumns.push({
                                        id: column.id,
                                        name: column.name
                                    });
                                }

                                if(Object.keys(column).indexOf('editable') > -1 && column.editable.type === 'select') {
                                    selectEditableExists = [column.editable.selectUri, column.id];
                                }
                            });

                            if(selectEditableExists.length) {
                                getSelectEditable(selectEditableExists);
                            }

                            $scope.translateFilter($scope.filters);

                            angular.forEach($scope.data.columns, function(column) {
                                if(column.type === 'checkbox') {
                                    if(column.checkbox_default) {
                                        goToCheckAll(column.var);
                                    }

                                    allIsChecked(column.var);
                                }
                            });


                            $scope.fetching = false;
                        });
                };

                $scope.export = type => {
                    let criteria = $scope.getCriteria()
                    criteria.export = type

                    $http.post($scope.endPoint, criteria).then(res => {
                        $scope.$emit('success', 'default.basetable.notify.EXCEL')

                        EventBus.$emit('ng:emit', {
                            name: 'downloadable',
                            data: {
                                name: res.data.filename,
                                status: true
                            }
                        })
                    }, () => {
                        $scope.$emit('error', 'default.basetable.notify.error.EXCEL')
                    })
                }

                $scope.getCriteria = () => {
                    let data = $scope.criteria

                    if ($attrs.customData) {
                        data.custom = {}
                        data.custom = $scope.customData({ data: data.custom })
                    }

                    if ($attrs.beforeFetch) {
                        data = $scope.beforeFetch({ criteria: data })
                    }

                    return data
                }

                $scope.paginate = () => {
                    let criteria = $scope.getCriteria()

                    $localStorage[$state.current.name + '-pageSize'] = criteria.pageSize
                    $localStorage[$state.current.name + '-sortBy'] = criteria.sortBy
                    $localStorage[$state.current.name + '-sortOrder'] = criteria.sortOrder
                    $localStorage[$state.current.name + '-search'] = criteria.search

                    $state.go($scope.ref, criteria)
                }

                $scope.setSortBy = function (columnId) {
                    var column = $scope.getColumn(columnId);
                    if (column && column.disableSorting) {
                        return false;
                    }

                    if ($scope.criteria.sortBy == columnId) {
                        $scope.criteria.sortOrder = $scope.criteria.sortOrder.toLowerCase() == 'asc' ? 'desc' : 'asc';
                    } else {
                        $scope.criteria.sortBy = columnId;
                        $scope.criteria.sortOrder = 'desc';
                    }
                    $scope.paginate();
                };


                $scope.setFilterEditor = function (enabled) {
                    let filtersAreValid = true;
                    $scope.criteria.search.forEach(item => {
                        let filter = $scope.decodeFilter(item);
                        if((filter.operator === '~~' || filter.operator === '!~~') && filter.value === '') {
                            $scope.$emit('error', 'default.basetable.notify.error.EMPTYVALUE')
                            filtersAreValid = false;
                        }
                    })
                    if (!filtersAreValid) {
                        return;
                    }
                    $scope.filterEditor = enabled;
                    if (!enabled) {
                        $scope.paginate();
                    }
                };

                $scope.removeFilter = function (index) {
                    $scope.filters.splice(index, 1);
                };

                $scope.addFilter = function (filter) {
                    $scope.filters.push({
                        field: '',
                        operator: '=',
                        value: ''
                    });
                };

                $scope.encodeFilter = function (filter) {
                    return filter.field + ':' + filter.operator + ':' +
                        (filter.selectLink ? filter.selectLink : filter.value);
                };

                $scope.decodeFilter = function (raw) {
                    if (angular.isObject(raw)) {
                        return raw;
                    }

                    var spliced = raw.split(':');

                    return {
                        field: spliced[0],
                        operator: spliced[1],
                        value: spliced[2]
                    };
                };

                $scope.getColumn = function (columnId) {
                    var col = undefined;
                    angular.forEach($scope.data.columns, function (column) {
                        if (column.id == columnId) {
                            col = column;
                        }
                    });
                    return col;
                };

                (function ($scope) {
                    // fill filters from criteria given from url/stateParams
                    $scope.filters = [];
                    angular.forEach($scope.criteria.search, function (encodedFilter) {
                        $scope.filters.push($scope.decodeFilter(encodedFilter));
                    })
                })($scope);

                $scope.translateFilter = function (filters) {
                    $scope.criteria.search = [];
                    angular.forEach(filters, function (filter, filterKey) {
                        $scope.criteria.search.push($scope.encodeFilter(filter));

                        if(angular.isDefined($scope.data.columns)) {
                            angular.forEach($scope.data.columns, function(column) {
                                if(column.id == filter.field) {
                                    $scope.filters[filterKey]['fieldTranslate'] = column.name;
                                    $scope.filters[filterKey]['translate'] = column.translate;

                                    if((column.type === 'select') && column.translate && filter.operator.indexOf('~~') === -1) {
                                        $scope.filters[filterKey]['value'] =
                                            $scope.filters[filterKey]['value']
                                                .substr($scope.filters[filterKey]['value'].lastIndexOf('.') + 1)
                                                .toLowerCase();

                                        angular.forEach(column.options, function(option) {
                                            $scope.filters[filterKey]['fullKey'] = $scope.filters[filterKey]['value'] ?
                                                (option.substr(0, option.lastIndexOf('.')) + '.' +
                                                $scope.filters[filterKey]['value'].toUpperCase()) : '';

                                            return true;
                                        });
                                    }
                                }
                            });
                        }
                    });
                };

                $scope.$watch(
                    function () {
                        return $scope.filters;
                    },
                    function (newValue, oldValue) {
                        $scope.translateFilter(newValue);
                    },
                    true /* deep watch */
                );

                $scope.getActionParams = function (item, action) {
                    if (typeof item.actions === 'undefined') {
                        item.actions = {}
                    }

                    if (typeof item.actions[action.name] === 'undefined') {
                        item.actions[action.name] = angular.extend({}, action.params);
                        angular.forEach(action.params, function (param, paramKey) {
                            angular.forEach(item, function (prop, propKey) {
                                item.actions[action.name][paramKey] = item.actions[action.name][paramKey].replace('{' + propKey + '}', prop);
                            });
                        });
                    }

                    return item.actions[action.name];
                };

                $scope.getParams = function(tab, row) {
                    var params = {};

                    angular.forEach(tab, function (val, key) {
                        if(key == '#') {
                            params[key] = val
                        } else {
                            params[key] = row[val];
                        }
                    });

                    return params;
                };

                $scope.setColumnEditor = function (enabled) {
                    $scope.columnEditor = enabled;

                    if (!enabled) {
                        $scope.criteria.columns = [];
                        angular.forEach($scope.data.columns, function (column) {
                            if (column.visible != column.defaults.visible) {
                                $scope.criteria.columns.push({
                                    id: column.id,
                                    visible: column.visible
                                });
                            }
                        });
                        $localStorage[$state.current.name + '-columns'] = $scope.criteria.columns;
                    }
                };
                $scope.toggleColumn = function (column) {
                    column.visible = !column.visible;
                };
                $scope.resetColumns = function () {
                    $scope.criteria.columns = [];
                    angular.forEach($scope.data.columns, function (column) {
                        column.visible = column.defaults.visible;
                    });
                    delete $localStorage[$state.current.name + '-columns'];
                };

                $scope.parseValue = function (value) {
                    return value.toString().indexOf(';') !== -1 ? value.split(';')[0] : value;
                };

                var allIsChecked = function (name) {
                    var notSelected = true;

                    if(typeof $localStorage[name + '_checkbox'] === 'undefined') {
                        $localStorage[name + '_checkbox'] = [];
                    }

                    angular.forEach($scope.data.rows, function(row) {
                        if(Object.keys(row).indexOf('checkbox_' + name) !== -1) {
                            var value = row['checkbox_' + name],
                                parseValue = $scope.parseValue(value),
                                idx = $localStorage[name + '_checkbox'].indexOf(parseValue);

                            if(idx === -1) {
                                notSelected = false;
                            }
                        }
                    });

                    $scope.checkboxAll[name] = notSelected;
                };

                $scope.toggleChecked = function(name, value) {
                    var fullName = name + '_checkbox',
                        parseValue = $scope.parseValue(value),
                        idx = $localStorage[fullName].indexOf(parseValue);

                    if(idx !== -1) {
                        $localStorage[fullName].splice(idx, 1);

                        $scope.checkboxAll[name] = false;
                    } else {
                        $localStorage[fullName].push(parseValue);

                        allIsChecked(name);
                    }
                };

                $scope.isChecked = function(name, value) {
                    var fullName = name + '_checkbox';

                    if(Object.keys($localStorage).indexOf(fullName) === -1) {
                        $localStorage[fullName] = [];
                    }

                    return $localStorage[fullName].indexOf(value) > -1 ? true : false;
                };

                $scope.checkboxAll = {};

                var goToCheckAll = function(name) {
                    if(typeof $localStorage[name + '_checkbox'] === 'undefined') {
                        $localStorage[name + '_checkbox'] = [];
                    }

                    angular.forEach($scope.data.rows, function(row) {
                        if(Object.keys(row).indexOf('checkbox_' + name) !== -1) {
                            var value = row['checkbox_' + name],
                                parseValue = $scope.parseValue(value),
                                idx = $localStorage[name + '_checkbox'].indexOf(parseValue);

                            if(idx === -1) {
                                $localStorage[name + '_checkbox'].push(parseValue);
                            }
                        }
                    });
                };

                $scope.allToggleChecked = function (name) {
                    if(typeof $scope.checkboxAll[name] === 'undefined') {
                        $scope.checkboxAll[name] = false;
                    }

                    angular.forEach($scope.data.rows, function(row) {
                        if(Object.keys(row).indexOf('checkbox_' + name) !== -1) {
                            var value = row['checkbox_' + name],
                                parseValue = $scope.parseValue(value),
                                idx = $localStorage[name + '_checkbox'].indexOf(parseValue);

                            if(!$scope.checkboxAll[name]) {
                                if(idx !== -1) {
                                    $localStorage[name + '_checkbox'].splice(idx, 1);
                                }
                            } else if(idx === -1) {
                                $localStorage[name + '_checkbox'].push(parseValue);
                            }
                        }
                    });
                };

                $scope.getBold = function(row, aliased, operator, value) {
                    switch(operator) {
                        case '=':
                            return row[aliased] === value;
                        case '<':
                            return row[aliased] < value;
                        case '>':
                            return row[aliased] > value;
                    }

                    return false;
                };

                $scope.render = function(value, row, column) {
                    if ($scope.renderers && $scope.renderers[column.id]) {
                        return $sce.trustAsHtml($scope.renderers[column.id](value, row, column));
                    }

                    return '[' + value + ']';
                };

                $scope.renderRow = function (row, columns) {
                    if ($scope.additionalRow) {
                        return $sce.trustAsHtml($scope.additionalRow(row, columns));
                    }

                    return false;
                };

                $scope.cellsInEditMode = {}

                $scope.$on('editable_mode', (event, column_id, bool) => {
                    let column = null

                    angular.forEach($scope.data.columns, c => {
                        if(c.id === column_id) {
                            column = c
                        }
                    })

                    $scope.data.rows.forEach(row => {
                        if(bool) {
                            $scope.cellClick(row, column)
                        } else if($scope.cellsInEditMode[row.id + '-' + column.id] && $scope.cellsInEditMode[row.id + '-' + column.id].value === $scope.getCellValue(row, column)) {
                            $scope.cellReset(null, row, column)
                        }
                    })
                })

                $scope.cellClick = function (row, column) {
                    if (!column.editable) return;
                    if ($scope.cellInEditMode(row, column)) return;

                    $scope.cellsInEditMode[row.id + '-' + column.id] = {
                        value: row[column.id],
                        saving: false
                    }
                }

                $scope.cellReset = function ($event, row, column) {
                    if(!$scope.cellInEditMode(row, column)) return

                    if($event) {
                        $event.stopPropagation()
                    }

                    delete $scope.cellsInEditMode[row.id + '-' + column.id]
                }

                $scope.cellApply = function ($event, row, column) {
                    if (!$scope.cellInEditMode(row, column)) return;
                    $event.stopPropagation()
                    $scope.cellsInEditMode[row.id + '-' + column.id].saving = true

                    $http.post(column.editable.postUri, {
                        row: row,
                        column: column.id,
                        value: $scope.cellsInEditMode[row.id + '-' + column.id].value
                    }).then(function (response) {
                        angular.forEach(response.data, function (fieldValue, fieldName) {
                            row[fieldName] = fieldValue
                        })

                        delete $scope.cellsInEditMode[row.id + '-' + column.id]
                    }, function (error) {
                        $scope.$emit('notify', {
                            translate: false,
                            message: error.statusText,
                            status: 'danger'
                        });
                        delete $scope.cellsInEditMode[row.id + '-' + column.id]
                    })
                }

                $scope.cellInEditMode = function (row, column) {
                    return $scope.cellsInEditMode.hasOwnProperty(row.id + '-' + column.id)
                }

                $scope.cellKeyPress = function (event, row, column) {
                    if (event.which === 13) {
                        $scope.cellApply(event, row, column)
                    } else if (event.which === 27) {
                        $scope.cellReset(event, row, column)
                    }
                }

                $scope.getCell = function (row, column) {
                    if (!$scope.cellInEditMode(row, column)) return false;
                    return $scope.cellsInEditMode[row.id + '-' + column.id];
                }

                $scope.getCellValue = function (row, column) {
                    var val = row[column.id]
                    if (val === '' && column.defaultValue) {
                        val = column.defaultValue
                    }
                    return val
                }

                $scope.saveFilter = function () {
                    var getName = function (filters, columns) {
                        var name = [];
                        angular.forEach(filters, function (val) {
                            name.push(
                                $filter('translate')(val.fieldTranslate)
                                + ' ' +
                                val.operator
                                + ' ' +
                                ((val.translate && val.operator.indexOf('~~') === -1) ? $filter('translate')(val.fullKey) : val.value )
                            )
                        });

                        name = name.join(', ')

                        var desc = [];
                        angular.forEach(columns, function (col) {
                            desc.push(
                                (col.visible ? '+' : '-')
                                +
                                $filter('translate')($scope.getColumn(col.id).name)
                            )
                        })

                        return name + '<br/><small>' + desc.join(', ') + '</small>'
                    }

                    var name = getName($scope.filters, $scope.criteria.columns)

                    if (name === '<br/><small></small>') return;

                    var alreadySaved = false
                    angular.forEach($scope.savedFilters, function (val) {
                        if (name === val.name) {
                            alreadySaved = true
                        }
                    });

                    if (!alreadySaved) {
                        $http
                            .post('api/users/filter/store', {
                                state: $scope.endPoint,
                                body: JSON.stringify({
                                    name: name,
                                    filters: angular.copy($scope.filters),
                                    columns: angular.copy($scope.criteria.columns)
                                })
                            })
                            .then(function (response) {
                                var newFilter = response.data;

                                $scope.savedFilters.push(newFilter);
                            })
                    }
                };

                $scope.applyFilters = function (sf) {
                    var filters = sf.filters

                    $scope.filters = filters;
                    $scope.translateFilter(filters)

                    $scope.criteria.columns = sf.columns

                    $scope.paginate()
                }

                $scope.removeSavedFilter = function (filter) {
                    $scope.savedFilters = $scope.savedFilters.filter(function (el) {
                        return el.name !== filter.name
                    });
                    $http.get('/api/users/filter/remove/' + filter.id);
                }

                $scope.toggleMultilevelIfAvailable = function (row) {
                    if ($scope.multilevel) {
                        row.multilevel = !row.multilevel
                    }
                }

                $scope.fire = (evtName, data) => {
                    if ($scope.events) {
                        $scope.events({
                            event: evtName,
                            data: data
                        })
                    }
                }

                $scope.filterFormat = function(name) {
                    return name.replace(/ /g, '&nbsp;').replace(/,/g, ', ');
                }

                $scope.$on('table-refresh', $scope.fetchData);

                $scope.fetchData();
                $scope.$state = $state;
                window.scope = $scope;

                $scope.updateCategoryPriceGroup = (key, category_price_group) => {
                    $scope.filters[key].value = category_price_group
                }

                $scope.updatePermission = (permission, key) => {
                    $scope.filters[key].value = permission.name
                }

                $scope.isSum = () => {
                    return Object.keys($scope.data.sum).length
                }

                $scope.openPreview = (value, columnId) => {
                    $scope.$emit('preview', value, columnId)
                }

                // to samo w OptiRate.vue
                $scope.rate_max = {
                    1: 2,
                    2: 3
                }

                $scope.rate = null

                $scope.openRate = (ref_type, row) => {
                    $scope.rate = null

                    setTimeout(() => {
                        $scope.rate = {
                            ref_type: ref_type,
                            ref_id: row.id,
                            object: {
                                rate: row.rate,
                                description: row.rate_description,
                                user_id: row.rate_user_id,
                                created_at: row.rate_created_at,
                                updated_at: row.rate_updated_at,
                                conditions: row.rate_condition_ids ? row.rate_condition_ids.split(',').map(function(c) {
                                    return {
                                        id: parseInt(c)
                                    }
                                }) : [],
                                user: {
                                    firstName: row.rate_user_first_name,
                                    lastName: row.rate_user_last_name
                                }
                            }
                        }

                        $scope.$apply()
                    }, 1)
                }
}]}}])